import Vue from 'vue'
import VueRouter from 'vue-router'

const auth = JSON.parse(localStorage.getItem('user'))
const admin = JSON.parse(localStorage.getItem('admin'))

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // TELAS DO DASHBOARD USUARIO
    {
      path: '/',
      name: 'centralstart',
      component: () => import('@/views/central/Inicio.vue'),
      meta: {
        pageTitle: 'Inicio',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/central',
      name: 'central',
      component: () => import('@/views/central/Inicio.vue'),
      meta: {
        pageTitle: 'Inicio',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cadastrar-grupo',
      name: 'cadastrar-grupo',
      component: () => import('@/views/central/CadastrarGrupo.vue'),
      meta: {
        pageTitle: 'Cadastrar Grupo',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Cadastrar Grupo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/meus-grupos',
      name: 'meus-grupos',
      component: () => import('@/views/central/MeusGrupos.vue'),
      meta: {
        pageTitle: 'Meus Grupos',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Meus Grupos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/funcoes',
      name: 'funcoes',
      component: () => import('@/views/central/Funcoes.vue'),
      meta: {
        pageTitle: 'Funcoes de Grupo',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Funcoes de Grupo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/historico',
      name: 'historico',
      component: () => import('@/views/central/Historico.vue'),
      meta: {
        pageTitle: 'Histórico de Pagamentos',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Histórico de Pagamentos',
            active: true,
          },
        ],
      },
    },

    {
      path: '/entrar',
      name: 'login',
      component: () => import('@/views/auth-member/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/registrar',
      name: 'register',
      component: () => import('@/views/auth-member/Register.vue'),
      meta: {
        layout: 'full',
      },
    },

    // TELA DE LOGIN REVENDA
    {
      path: '/login-painel',
      name: 'login-reseller',
      component: () => import('@/views/auth-reseller/Login.vue'),
      meta: {
        layout: 'full',
      },
    },

    // TELA DASHBOARD REVENDA
    {
      path: '/painel',
      name: 'painel',
      component: () => import('@/views/painel/Inicio.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Inicio',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/cadastrar-bot',
      name: 'cadastrar-bot',
      component: () => import('@/views/painel/CadastrarBot.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Cadastrar BOT',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Cadastrar BOT',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/cadastrar-cliente',
      name: 'cadastrar-cliente',
      component: () => import('@/views/painel/CadastrarCliente.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Cadastrar Novo Cliente',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Cadastrar Novo Cliente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/listar-clientes',
      name: 'listar-clientes',
      component: () => import('@/views/painel/ListarClientes.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Listagem dos Clientes',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Listagem dos Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/cadastrar-grupo',
      name: 'painel-cadastrar-grupo',
      component: () => import('@/views/painel/CadastrarGrupo.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Cadastrar Grupo',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Cadastrar Grupo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/gerenciar-grupos',
      name: 'painel-gerenciar-grupo',
      component: () => import('@/views/painel/GerenciarGrupo.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Gerenciar Grupo',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Gerenciar Grupo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/cadastrar-missao',
      name: 'painel-cadastrar-missao',
      component: () => import('@/views/painel/CadastrarMissao.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Cadastrar Missao',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Cadastrar Missao',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/gerenciar-solicitacoes',
      name: 'painel-gerenciar-solicitacoes',
      component: () => import('@/views/painel/GerenciarSolicitacoes.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Gerenciar Resultados de Missão',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Gerenciar Solicitações',
            active: true,
          },
        ],
      },
    },
    {
      path: '/painel/renovacoes',
      name: 'renovacoes',
      component: () => import('@/views/painel/Renovar.vue'),
      meta: {
        layout: 'admin',
        pageTitle: 'Renovar Grupos',
        requiresAuthAdmin: true,
        breadcrumb: [
          {
            text: 'Renovar Grupos',
            active: true,
          },
        ],
      },
    },

    // TELA DE ERRO
    {
      path: '/error',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (auth) {
      next()
    } else {
      next({
        path: '/entrar',
      })
    }
  } else if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
    if (admin) {
      next()
    } else {
      next({
        path: '/login-painel',
      })
    }
  }
  if (to.path === '/entrar' && auth) {
    next({
      path: '/central',
    })
  }

  if (to.path === '/login-painel' && admin) {
    next({
      path: '/painel',
    })
  }

  if (to.path === '/entrar' || to.path === '/registrar' || to.path === '/login-painel') {
    next()
  }
})

export default router
