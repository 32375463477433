/* eslint-disable class-methods-use-this */
import axios from 'axios'

const API_URL = 'https://wppbot-api-xx36i.ondigitalocean.app/'
// const API_URL = 'http://127.0.0.1:3000/'

class AuthService {
  login(user) {
    return axios
      .post(`${API_URL}user/login`, {
        username: user.username,
        password: user.password,
      })
      .then(response => {
        console.log(response)
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  loginAdmin(user) {
    return axios
      .post(`${API_URL}admin/login`, {
        username: user.username,
        password: user.password,
      })
      .then(response => {
        console.log(response)
        if (response.data.token) {
          localStorage.setItem('admin', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  register(user) {
    return axios
      .post(`${API_URL}user/create`, {
        name: user.name, username: user.username, password: user.password, phone: user.phone, reseller_id: user.reseller_id,
      })
      .then(response => response.data)
  }

  logout() {
    localStorage.removeItem('user')
  }
}

export default new AuthService()
